import { OidcUserStatus, useOidc, useOidcUser } from '@axa-fr/react-oidc';
import React, { useState, createContext, ReactNode, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/react';
import { useApi } from './ApiContext';

interface AuthContextProps {
  userName: string;
  userId: number;
  client: string;
  clientGuid: string;
  clientId: number;
  groupId: number;
  isLoading: boolean;
  isLoggedIn: boolean;
  roles: string[];
  isAdminOfClient: boolean;
  toggleValuerMode: (mode: boolean) => void;
  isValuerMode: boolean;
  fullName: string;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);
export const AuthProvider: React.FC<ReactNode> = ({ children }) => {
  const { oidcUser, oidcUserLoadingState } = useOidcUser();
  const { login, isAuthenticated } = useOidc();
  const [userId, setUserId] = useState<number>(0);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(oidcUserLoadingState === OidcUserStatus.Loading);
  const [userName, setUserName] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [client, setClient] = useState<string>('');
  const [clientGuid, setClientGuid] = useState<string>('');
  const [clientId, setClientId] = useState<number>(0);
  const [groupId, setGroupId] = useState<number>(0);
  const [roles, setRoles] = useState<string[]>([]);
  const [isAdminOfClient, setIsAdminOfClient] = useState<boolean>(false);
  const [isValuerMode, setIsValuerMode] = useState<boolean>(false);
  const api = useApi();

  useEffect(() => {
    if (isAuthenticated && oidcUser) {
      setIsLoggedIn(true);
      setUserName(oidcUser.name ?? userName);
      setUserId(parseInt(oidcUser.sub) ?? userId);
      setClient(oidcUser['Client Title']);
      setClientId(parseInt(oidcUser['Client ID']));
      setClientGuid(oidcUser['Client External ID']);
      setGroupId(oidcUser['Group ID']);
      setIsAdminOfClient(oidcUser.IsAdminOfClient === 'True');
      setIsValuerMode(oidcUser.IsValuerMode === 'True');
      setRoles(oidcUser.role);
      setFullName(oidcUser['Full Name']);
      Sentry.setUser({ email: oidcUser.name, clientName: oidcUser['Client Title'] as string });
    }
  }, [isAuthenticated, oidcUser]);

  useEffect(() => {
    if (!isAuthenticated) {
      login();
    }
  }, [isAuthenticated]);

  const toggleValuerMode = (mode: boolean): void => {
    setIsValuerMode(mode);
    api.post('/api/User/ToggleValuerMode', { userId, isValuerMode: mode });
  };

  const contextValue = React.useMemo(
    () => ({
      userName,
      userId,
      client,
      clientGuid,
      isLoading,
      isLoggedIn,
      roles,
      isAdminOfClient,
      toggleValuerMode,
      isValuerMode,
      clientId,
      groupId,
      fullName,
    }),
    [
      userName,
      userId,
      client,
      clientGuid,
      isLoading,
      isLoggedIn,
      roles,
      isAdminOfClient,
      toggleValuerMode,
      isValuerMode,
      clientId,
      groupId,
      fullName,
    ]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextProps => useContext(AuthContext);
