import React from 'react';
import { NavLink, Route, Routes, useParams, Link, useNavigate, Outlet } from 'react-router-dom';
import { LocationForm } from '../../components/Asset/LocationForm';
import { AssetMainForm } from '../../components/Asset/AssetMainForm';
import ImageViewer from '../../components/Asset/ImageViewer';
import InsuranceForm from '../../components/Asset/InsuranceForm';
import { Asset } from '../../models/Asset';
import { Accounting } from '../../components/Asset/Accounting';
import { ValuationEditor } from '../../components/Asset/ValuationEditor';
import { useAssetById } from '../../hooks/UseAsset';
import { useClient } from '../../contexts/ClientContext';

// interface Props {
//   children?: React.ReactNode;
// }

export const AssetEdit: React.FC<{}> = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { data: asset = {} as Asset } = useAssetById(parseInt(id));
  const history = useNavigate();
  const { isInsuranceOnly } = useClient();
  // useEffect(() => {
  //   if(id <= 0) {
  //     history.replace("/assets/search");
  //   }
  // }, [url])

  return (
    <div className="relative mx-auto sm:px-4 md:px-8">
      <div className="pt-8 pb-4">
        <div className="px-4 sm:px-6 md:px-0 flex justify-between">
          <h1 className="text-3xl font-extrabold text-gray-900">{asset !== undefined ? asset.reference : ''}</h1>
          {parseInt(id) > 0 && (
            <Link to={`/assets/${id}`} className="btn btn-secondary">
              View
            </Link>
          )}
        </div>
        <div className="px-4 sm:px-6 md:px-0">
          <div className="py-2">
            {/* Tabs */}
            <div className="lg:hidden">
              <label htmlFor="selected-tab" className="sr-only">
                Select a tab
              </label>
              <select
                id="selected-tab"
                name="selected-tab"
                defaultValue="Detail"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
              >
                <option key="Detail">Detail</option>

                <option key="Notes">Notes</option>

                <option key="Photos">Photos</option>

                <option key="Components">Components</option>
              </select>
            </div>
            <div className="hidden lg:block">
              <div className="border-gray-200">
                <nav className="flex space-x-4 mb-2">
                  <NavLink
                    to="."
                    end
                    className={({ isActive }) =>
                      `text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md ${
                        isActive && 'bg-gray-200 text-gray-800'
                      }`
                    }
                  >
                    <span>Detail</span>
                  </NavLink>
                  {parseInt(id) > 0 && !isInsuranceOnly && (
                    <NavLink
                      to="Valuation"
                      className={({ isActive }) =>
                        `text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md ${
                          isActive && 'bg-gray-200 text-gray-800'
                        }`
                      }
                    >
                      <span>Valuation</span>
                    </NavLink>
                  )}
                  {parseInt(id) > 0 && (
                    <NavLink
                      to="Images"
                      className={({ isActive }) =>
                        `text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md ${
                          isActive && 'bg-gray-200 text-gray-800'
                        }`
                      }
                    >
                      <span>Images</span>
                    </NavLink>
                  )}
                  {/* Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" */}
                  {parseInt(id) > 0 && !isInsuranceOnly && (
                    <NavLink
                      to="Accounting"
                      className={({ isActive }) =>
                        `text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md ${
                          isActive && 'bg-gray-200 text-gray-800'
                        }`
                      }
                    >
                      {/* Heroicon name: credit-card */}
                      <span>Accounting</span>
                    </NavLink>
                  )}
                  {/* {asset.isInsuranceRequired && ( */}
                  {parseInt(id) > 0 && (
                    <NavLink
                      to="Insurance"
                      className={({ isActive }) =>
                        `text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md ${
                          isActive && 'bg-gray-200 text-gray-800'
                        }`
                      }
                    >
                      {/* Heroicon name: credit-card */}
                      <span>Insurance</span>
                    </NavLink>
                  )}
                  {/* )} */}
                  {parseInt(id) > 0 && (
                    <NavLink
                      to="Location"
                      className={({ isActive }) =>
                        `text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md ${
                          isActive && 'bg-gray-200 text-gray-800'
                        }`
                      }
                    >
                      <span>Location</span>
                    </NavLink>
                  )}
                </nav>
              </div>
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
