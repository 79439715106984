import React, { useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/solid';
import { CameraIcon, PencilSquareIcon, PhotoIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Asset, Insurance } from '../../models/Asset';
import { useAssetById } from '../../hooks/UseAsset';
import { currencyFormat, percentFormat, twoDecimalsPercentFormat } from '../../Format';
import { BasicTable } from '../../components/BasicTable';
import { Content } from '../../models/Content';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { useApi } from '../../contexts/ApiContext';
import { ImageDisplay, ImagesImport } from '../../models/Image';
import { Roles } from '../../models/Role';
import { useAuth } from '../../contexts/AuthContext';
import ImageModal from './ImageModal';
import ContentModal from '../Content/ContentModal';
import { DeleteConfirmation } from '../../components/DeleteConfirmation';
import { bulkUploadImages } from '../../Helper';

export const InsuranceView: React.FC = () => {
  const { roles, clientGuid } = useAuth();
  const cameraRef = useRef<HTMLInputElement>(null);
  const { addToast } = useToast();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const api = useApi();
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [contentToEdit, setContentToEdit] = useState<Content>({} as Content);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [contentsName, setContentsName] = useState<string>('');
  const { id = '' } = useParams<{ id: string }>();
  const {
    data: {
      contents,
      insurance: {
        coverage,
        total,
        demoMonths,
        replacementAdjustmentPct,
        escalationFactorPct,
        professionalFeesPct,
        debrisRemovalPct,
        debrisRemovalMinimum,
        debrisRemoval,
        professionalFees,
        escalationFees,
        totalPlusEscalation,
        totalPlusEscalationPlusProfessional,
        leadBuildMonths,
      } = {} as Insurance,
    } = {} as Asset,
    isLoading,
    isError,
    refetch,
  } = useAssetById(parseInt(id));

  const openEditModal = (content: Content): void => {
    setContentToEdit(content);
    setEditModal(true);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, contentName: string) => {
    const selectedFiles: File[] = Array.from(e.target.files as FileList);
    bulkUploadImages(selectedFiles).then((result) => {
      const imageImport: ImagesImport = {
        data: result,
        assetId: id,
      };
      api
        .post(`/api/Asset/ImportImages`, { images: imageImport.data, assetId: id, contentName })
        .then(
          ({ data }) => {
            addToast('Importing Images');
          },
          (error) => {
            addToast('Image Upload Failed', ToastType.Error);
          }
        )
        .finally(() => {
          queryClient.invalidateQueries(['asset', parseInt(id)]);
        });
    });
  };

  const deleteContent = (): void => {
    api
      .post('/api/Asset/DeleteContents', { assetId: id, name: contentsName })
      .then(
        ({ data }) => {
          if (data === 1) {
            addToast(`Deleted Contents ${contentsName}`);
          }
        },
        (error) => {
          addToast('Unable to delete Contents');
        }
      )
      .finally(() => {
        queryClient.invalidateQueries(['asset', parseInt(id)]);
      });
  };
  const columns = React.useMemo<ColumnDef<Content>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        meta: {
          className: 'px-2 py-2.5 text-sm font-normal text-gray-500',
        },
      },
      {
        header: 'Type',
        accessorKey: 'type',
        meta: {
          className: 'px-2 py-2.5 text-sm font-normal text-gray-500',
        },
      },

      {
        header: 'Total',
        accessorKey: 'rate',
        meta: {
          className: 'px-2 py-2.5 text-sm font-normal text-gray-500',
        },
        cell: ({ row }): JSX.Element => (
          <div className="flex items-center space-x-1 whitespace-nowrap">
            <span>{currencyFormat.format(row.original.rate * row.original.quantity)}</span>{' '}
            <div className="text-xs italic">
              ( {row.original.quantity} x {currencyFormat.format(row.original.rate)})
            </div>
          </div>
        ),
      },
      {
        header: '',
        accessorKey: 'imageUrls',
        meta: {
          headerClassName: 'text-right whitespace-normal',
          className: 'px-2 py-3 whitespace-nowrap text-sm font-normal text-gray-500',
        },
        cell: ({ row }): React.ReactElement => (
          <div className="flex flex-row justify-end space-x-4">
            <div className="flex flex-row space-x-1">
              {row.original.imageUrls &&
                row.original.imageUrls.slice(0, 4).map((image, index) => (
                  <button
                    onClick={(): void => {
                      setIsModalOpen(true);
                      setImageUrls(row.original.imageUrls || []);
                      setIndex(index);
                    }}
                    disabled={row.original.imageUrls?.length == 0}
                  >
                    <img
                      className="h-12 w-12 flex-none rounded-md bg-gray-50"
                      src={`${import.meta.env.VITE_BLOB_STORAGE_URL}/${clientGuid}/${image}`}
                      alt=""
                    />
                  </button>
                ))}
              {row.original.imageUrls && row.original.imageUrls?.length > 4 && (
                <span className="flex-shrink-0 text-xs font-medium leading-5 self-center">
                  +{row.original.imageUrls?.length - 4}
                </span>
              )}
              <input
                accept="image/*"
                className="hidden"
                name="files[]"
                id="files"
                type="file"
                multiple
                ref={cameraRef}
                onChange={(e): void => handleFileUpload(e, row.original.name)}
              />
              <button
                className="h-12 w-12 text-white border-2 border-dashed border-gray-300 rounded-lg focus:outline-none "
                onClick={(): void => cameraRef.current?.click()}
                type="button"
              >
                <div className="flex flex-col items-center">
                  <PlusIcon className="w-5 h-5 text-gray-400" />
                </div>
              </button>
            </div>

            {/* <PhotoIcon
              className={`h-4 w-4 ${
                row.original?.images.length == 0 ? 'text-gray-300' : 'text-indigo-600'
              }  font-medium rounded-md focus:outline-none`}
            /> */}
          </div>
        ),
      },
      {
        header: (): React.ReactElement => (
          <button
            onClick={(): void =>
              openEditModal({ name: '', type: 'Furniture and Fittings', rate: 100, quantity: 1 } as Content)
            }
            className="inline-flex items-center"
          >
            <PlusIcon className="mr-5 h-4 w-4 text-indigo-600" />
          </button>
        ),
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-right whitespace-normal',
          className: 'px-2 py-3 whitespace-nowrap text-sm leading-5',
        },
        cell: ({ row }): React.ReactElement => (
          <div className="flex flex-row justify-end space-x-4">
            <button onClick={(): void => openEditModal(row.original)} className="btn-link">
              <PencilSquareIcon className="text-indigo-600 h-4 w-4" />
            </button>
            {!roles.includes(Roles.Viewer) && (
              <button
                onClick={(): void => {
                  setDeleteConfirmation(true);
                  setContentsName(row.original.name);
                }}
              >
                <TrashIcon className="h-4 w-4 text-indigo-600  font-medium rounded-md focus:outline-none hover:text-red-700" />
              </button>
            )}
          </div>
        ),
      },
    ],
    [contents]
  );

  return (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
      <div>
        {coverage === 'Full' ? (
          <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
            <div>
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">Base</dt>
                <dd className="mt-1 flex flex-col justify-between items-baseline md:block lg:flex">
                  <div className=" text-2xl font-semibold text-indigo-600">{currencyFormat.format(total)}</div>
                </dd>
              </div>
            </div>

            <div>
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">Base + Esc</dt>
                <dd className="mt-1 flex flex-col justify-between items-baseline md:block lg:flex">
                  <div className=" text-2xl font-semibold text-indigo-600">{currencyFormat.format(totalPlusEscalation)}</div>
                  <div className="text-sm font-medium text-gray-500">
                    {currencyFormat.format(escalationFees)} Escalation Fees
                  </div>
                </dd>
              </div>
            </div>

            <div>
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">Base + Esc + Prof</dt>
                <dd className="mt-1 flex flex-col justify-between items-baseline md:block lg:flex">
                  <div className=" text-2xl font-semibold text-indigo-600">
                    {currencyFormat.format(totalPlusEscalationPlusProfessional)}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {currencyFormat.format(professionalFees)} Professional Fees
                  </div>
                </dd>
              </div>
            </div>
          </dl>
        ) : (
          <dl className="w-1/3 mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:divide-y-0 md:divide-x">
            <div>
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">Base</dt>
                <dd className="mt-1 flex flex-col justify-between items-baseline md:block lg:flex">
                  <div className=" text-2xl font-semibold text-indigo-600">{currencyFormat.format(total)}</div>
                </dd>
              </div>
            </div>
          </dl>
        )}
      </div>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 mt-8 sm:grid-cols-3">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Demolition</dt>
          <dd className="mt-1 text-sm text-gray-900">{demoMonths} Months</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Rebuild/Reconstruction</dt>
          <dd className="mt-1 text-sm text-gray-900">{leadBuildMonths} Months</dd>
        </div>
        <div className="sm:col-span-1" />

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Escalation Adjustment</dt>
          <dd className="mt-1 text-sm text-gray-900">{twoDecimalsPercentFormat.format(escalationFactorPct)}</dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Professional Fees Adjustment</dt>
          <dd className="mt-1 text-sm text-gray-900">{twoDecimalsPercentFormat.format(professionalFeesPct)}</dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Replacement Adjustment</dt>
          <dd className="mt-1 text-sm text-gray-900">{twoDecimalsPercentFormat.format(replacementAdjustmentPct)}</dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Debris Removal Rate</dt>
          <dd className="mt-1 text-sm text-gray-900">{twoDecimalsPercentFormat.format(debrisRemovalPct)}</dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Minimum Debris Removal Cost</dt>
          <dd className="mt-1 text-sm text-gray-900">{currencyFormat.format(debrisRemovalMinimum)}</dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-bold text-gray-500">Estimated Debris Removal Cost</dt>
          <dd className="mt-1 text-sm font-bold text-gray-900">{currencyFormat.format(debrisRemoval)}</dd>
        </div>
      </dl>

      <div className="pt-6">
        <div className="font-bold text-gray-700">Contents</div>

        <BasicTable data={contents || []} columns={columns} />
      </div>
      <ImageModal
        isOpen={isModalOpen}
        closeModal={(): void => setIsModalOpen(false)}
        images={[]}
        imageUrls={imageUrls}
        index={index}
      />
      <ContentModal
        isOpen={editModal}
        closeModal={(): void => setEditModal(false)}
        content={contentToEdit}
        assetId={id || ''}
      />
      <DeleteConfirmation
        isOpen={deleteConfirmation}
        setOpen={(): void => setDeleteConfirmation(false)}
        itemType={`${contentsName}`}
        deleteAction={(): void => deleteContent()}
      />
    </div>
  );
};
