import { ChatBubbleBottomCenterTextIcon, EyeIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import React from 'react';
import { Asset } from '../../models/Asset';
import { useClient } from '../../contexts/ClientContext';

const AssetSummaryView: React.FC<Asset> = ({
  status,
  valuer,
  isHeldForSale,
  isInvestment,
  isControlledForFinancialPurpose,
  isHeritageAsset,
  acquisitionDate,
  decommissionDate,
  indexedFromDate,
  history,
  valuationType,
  marketApproach,
  assetCustodian,
  valuationPolicy,
  notes,
  customFields,
}) => {
  const { isInsuranceOnly } = useClient();
  const generalNote: string | undefined = notes?.find((note) => note.tag === '')?.detail;
  const valuationPolicyNote: string | undefined = notes?.find((note) => note.tag === 'Valuation Policy')?.detail;
  return (
    <div className="flex flex-col">
      <div className="">
        <div className="align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-md bg-white">
            <aside className="xl:block pl-4 xl:pl-8">
              <h2 className="sr-only">Details</h2>
              <div className="space-y-4 py-6">
                {status === 'Complete' && (
                  <div className="flex items-center space-x-2">
                    {/* Heroicon name: solid/lock-open */}
                    <EyeIcon className="h-5 w-5 text-indigo-500" />
                    <span className="text-indigo-700 text-sm font-medium">Complete</span>
                  </div>
                )}
                {status === 'To Be Inspected' && (
                  <div className="flex items-center space-x-2">
                    {/* Heroicon name: solid/lock-open */}
                    <EyeIcon className="h-5 w-5 text-red-500" />
                    <span className="text-red-700 text-sm font-medium">To Be Inspected</span>
                  </div>
                )}
                {status === 'Inspected' && (
                  <div className="flex items-center space-x-2">
                    {/* Heroicon name: solid/lock-open */}
                    <EyeIcon className="h-5 w-5 text-yellow-500" />
                    <span className="text-yellow-700 text-sm font-medium">Inspection Complete</span>
                  </div>
                )}

                {status === 'Not Required' && (
                  <div className="flex items-center space-x-2">
                    {/* Heroicon name: solid/lock-open */}
                    <EyeIcon className="h-5 w-5 text-gray-500" />
                    <span className="text-gray-700 text-sm font-medium">Inspection not Required</span>
                  </div>
                )}
                {generalNote !== undefined && (
                  <div className="flex items-center space-x-2">
                    <ChatBubbleBottomCenterTextIcon className="h-5 w-5 text-gray-400" />
                    <span className="text-gray-900 text-sm font-medium">{generalNote}</span>
                  </div>
                )}
                {valuer !== '' && (
                  <div className="flex items-center space-x-2">
                    <span className="text-gray-600 text-sm font-medium">
                      Valued by <span className="text-gray-900">{valuer}</span>
                    </span>
                  </div>
                )}

                {assetCustodian && (
                  <div className="flex items-center space-x-2">
                    <span className="text-gray-600 text-sm font-medium">
                      Asset Custodian: <span className="text-gray-900">{assetCustodian}</span>
                    </span>
                  </div>
                )}

                {acquisitionDate && (
                  <div className="flex items-center space-x-2">
                    {/* Heroicon name: solid/calendar */}
                    <span className="text-gray-600 text-sm font-medium">
                      Acquisition Date:{' '}
                      <span className="text-gray-900">
                        {acquisitionDate && format(new Date(acquisitionDate), 'dd MMM yyyy')}
                      </span>
                    </span>
                  </div>
                )}

                {decommissionDate && (
                  <div className="flex items-center space-x-2">
                    {/* Heroicon name: solid/calendar */}
                    <span className="text-gray-600 text-sm font-medium">
                      Decommission Date:{' '}
                      <span className="text-gray-900">
                        {decommissionDate && format(new Date(decommissionDate), 'dd MMM yyyy')}
                      </span>
                    </span>
                  </div>
                )}

                {indexedFromDate && (
                  <div className="flex items-center space-x-2">
                    {/* Heroicon name: solid/calendar */}
                    <span className="text-gray-600 text-sm font-medium">
                      Indexed From Date:{' '}
                      <span className="text-gray-900">
                        {indexedFromDate && format(new Date(indexedFromDate), 'dd MMM yyyy')}
                      </span>
                    </span>
                  </div>
                )}
              </div>

              {!isInsuranceOnly && (
                <div className="border-t border-gray-200 py-6 space-y-8">
                  <div>
                    <ul className="leading-8 space-x-1">
                      {isHeldForSale && (
                        <li className="inline">
                          <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5">
                            <div className="absolute flex-shrink-0 flex items-center justify-center">
                              <span className="h-1.5 w-1.5 rounded-full bg-indigo-700" aria-hidden="true" />
                            </div>
                            <div className="ml-3.5 text-sm font-medium text-gray-900">{history}</div>
                          </div>
                        </li>
                      )}

                      {isHeldForSale && (
                        <li className="inline">
                          <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5">
                            <div className="absolute flex-shrink-0 flex items-center justify-center">
                              <span className="h-1.5 w-1.5 rounded-full bg-indigo-700" aria-hidden="true" />
                            </div>
                            <div className="ml-3.5 text-sm font-medium text-gray-900">Held For Sale</div>
                          </div>
                        </li>
                      )}

                      {isInvestment && (
                        <li className="inline">
                          <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5">
                            <div className="absolute flex-shrink-0 flex items-center justify-center">
                              <span className="h-1.5 w-1.5 rounded-full bg-indigo-700" aria-hidden="true" />
                            </div>
                            <div className="ml-3.5 text-sm font-medium text-gray-900">Investment</div>
                          </div>
                        </li>
                      )}

                      {isControlledForFinancialPurpose && (
                        <li className="inline">
                          <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5">
                            <div className="absolute flex-shrink-0 flex items-center justify-center">
                              <span className="h-1.5 w-1.5 rounded-full bg-indigo-700" aria-hidden="true" />
                            </div>
                            <div className="ml-3.5 text-sm font-medium text-gray-900">Controlled</div>
                          </div>
                        </li>
                      )}

                      {isHeritageAsset && (
                        <li className="inline">
                          <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5">
                            <div className="absolute flex-shrink-0 flex items-center justify-center">
                              <span className="h-1.5 w-1.5 rounded-full bg-indigo-700" aria-hidden="true" />
                            </div>
                            <div className="ml-3.5 text-sm font-medium text-gray-900">Heritage</div>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              )}

              {!isInsuranceOnly && (
                <div className="border-t border-gray-200 py-6 space-y-8">
                  <div className="space-y-2">
                    <h2 className="text-sm font-medium text-gray-500">Valuation Approach</h2>
                    <ul className="leading-8">
                      {valuationPolicy !== 'Not to be Valued' ? (
                        <li className="inline mx-1">
                          <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5">
                            <div className="absolute flex-shrink-0 flex items-center justify-center">
                              <span className="h-1.5 w-1.5 rounded-full bg-indigo-700" aria-hidden="true" />
                            </div>
                            <div className="ml-3.5 text-sm font-medium text-gray-900">
                              {valuationType} {valuationType === 'Market' && ` - ${marketApproach?.type}`}
                            </div>
                          </div>
                        </li>
                      ) : (
                        <li className="inline">
                          <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5">
                            <div className="absolute flex-shrink-0 flex items-center justify-center">
                              <span className="h-1.5 w-1.5 rounded-full bg-red-500" aria-hidden="true" />
                            </div>
                            <div className="ml-3.5 text-sm font-medium text-gray-900">Not to be Valued</div>
                          </div>{' '}
                        </li>
                      )}
                    </ul>
                    {valuationPolicy === 'Not to be Valued' && valuationPolicyNote && (
                      <div className="flex items-center space-x-2">
                        <ChatBubbleBottomCenterTextIcon className="h-5 w-5 min-w-min text-gray-400" />
                        <span className="text-gray-900 text-sm font-medium">{valuationPolicyNote}</span>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {Object.keys(customFields).length > 0 && (
                <div className="py-6 border-t border-gray-200 space-y-4">
                  {Object.keys(customFields).map((key, value) => (
                    <div key={key} className="flex items-center space-x-2">
                      {/* Heroicon name: solid/calendar */}
                      <InformationCircleIcon className="h-5 w-5 text-gray-400" />
                      <span className="text-gray-600 text-sm font-medium">
                        {key}: <span className="ml-1 text-gray-900">{customFields[key]}</span>
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetSummaryView;
