import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import * as yup from 'yup';
import { useParams, unstable_usePrompt, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { Asset } from '../../models/Asset';
import { useAssetById, useUpdateAsset } from '../../hooks/UseAsset';
import { useApi } from '../../contexts/ApiContext';
import { HierarchyType } from '../../models/HierarchyType';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { ErrorMessage } from '../ErrorMessage';
import { removeTimezoneOffset } from '../../Helper';
import { useAssetSearch } from '../../contexts/AssetSearchContext';
import { useClient } from '../../contexts/ClientContext';
import Loader from '../../icons/Loader.svg?react';

export const AssetMainForm: React.FC = () => {
  const api = useApi();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { id = '' } = useParams<{ id: string }>();
  const { data: asset = {} as Asset, refetch } = useAssetById(parseInt(id));
  const { assets, setAssets } = useAssetSearch();
  const { isInsuranceOnly } = useClient();

  const assetSchema = yup.object().shape({
    reference: yup.string().max(30, 'Maximum length is 30.').required('Asset ID is required'),
    assetClassId: yup.number().min(1, 'Asset Class required').typeError('Asset Class required'),
    status: yup.string().required('Status is required'),
    acquisitionDate: yup.date().nullable(),
    decommissionDate: yup
      .date()
      .nullable()
      .when('acquisitionDate', ([acquisitionDate], schema) => {
        if (acquisitionDate) {
          return schema.min(acquisitionDate, 'Decomission date must be after Acquisition date');
        }
        return schema;
      }),
  });
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    getValues,
    formState: { isDirty, isSubmitted, errors },
  } = useForm<Asset>({
    defaultValues: {
      id: 0,
      name: '',
      reference: '',
      status: 'To Be Inspected',
      isHeldForSale: false,
      isControlledForFinancialPurpose: false,
    },
    resolver: yupResolver(assetSchema),
  });

  const assetClassIdWatch = watch('assetClassId') || 0;
  const assetTypeIdWatch = watch('assetTypeId') || 0;
  const history = useNavigate();
  const { addToast } = useToast();

  const updateAsset = useUpdateAsset();

  const { data: assetClassList = [{ value: asset.assetClassId, label: asset.assetClass }] as HierarchyType[] } = useQuery<
    HierarchyType[],
    Error
  >(['Asset Class List'], () => api.get<HierarchyType[]>('/api/Lookup/AssetClass?OpenOnly=true').then(({ data }) => data));

  const fetchAssetType = async (assetClassId: number): Promise<HierarchyType[]> =>
    api.get<HierarchyType[]>(`/api/Lookup/AssetType?assetClassId=${assetClassId}`).then(({ data }) => data);

  const {
    refetch: refetchAssetTypes,
    data: assetTypeList = [{ value: asset.assetTypeId, label: asset.assetType }] as HierarchyType[],
  } = useQuery<HierarchyType[], Error>(
    ['Asset Type List', assetClassIdWatch],
    async () => fetchAssetType(assetClassIdWatch),
    { enabled: false }
  );

  const fetchAssetSubType = async (assetTypeId: number): Promise<HierarchyType[]> =>
    api.get<HierarchyType[]>(`/api/Lookup/AssetSubType?assetTypeId=${assetTypeId}`).then(({ data }) => data);

  const {
    refetch: refetchAssetSubTypes,
    data: assetSubTypeList = [{ value: asset.assetSubTypeId, label: asset.assetSubType }] as HierarchyType[],
  } = useQuery<HierarchyType[], Error>(
    ['Asset Type List', assetTypeIdWatch],
    async () => fetchAssetSubType(assetTypeIdWatch),
    { enabled: false }
  );

  // const [radioState, setRadioState] = React.useState<boolean>(asset.isApportionment);

  useEffect(() => {
    reset({
      ...asset,
      acquisitionDate: asset?.acquisitionDate ? new Date(asset.acquisitionDate) : undefined,
      decommissionDate: asset?.decommissionDate ? new Date(asset.decommissionDate) : undefined,
      indexedFromDate: asset?.indexedFromDate ? new Date(asset.indexedFromDate) : undefined,
    });
  }, [asset]);

  useEffect(() => {
    if (assetClassIdWatch > 0) {
      refetchAssetTypes();
      refetchAssetSubTypes();
    }
  }, [assetClassIdWatch, assetClassList]);

  useEffect(() => {
    if (assetTypeList.length > 0) {
      refetchAssetSubTypes();
    }
  }, [assetTypeList]);

  useEffect(() => {
    if (assetTypeIdWatch > 0) {
      refetchAssetSubTypes();
    }
  }, [assetTypeIdWatch]);

  const onSubmit = handleSubmit((formAsset) => {
    setIsSaving(true);
    if (parseInt(id) > 0) {
      updateAsset.mutate(
        {
          ...asset,
          ...formAsset,
          assetClassId: formAsset.assetClassId === 0 ? undefined : formAsset.assetClassId,
          assetSubTypeId: formAsset.assetSubTypeId === 0 ? undefined : formAsset.assetSubTypeId,
          assetTypeId: formAsset.assetTypeId === 0 ? undefined : formAsset.assetTypeId,
          acquisitionDate:
            formAsset.acquisitionDate !== undefined && formAsset.acquisitionDate !== null
              ? removeTimezoneOffset(formAsset.acquisitionDate)
              : undefined,
          decommissionDate:
            formAsset.decommissionDate !== undefined && formAsset.decommissionDate !== null
              ? removeTimezoneOffset(formAsset.decommissionDate)
              : undefined,
          indexedFromDate:
            formAsset.indexedFromDate !== undefined && formAsset.indexedFromDate !== null
              ? removeTimezoneOffset(formAsset.indexedFromDate)
              : undefined,
        },
        {
          onSettled: () => {
            setIsSaving(false);
          },
        }
      );
      const newAssets = assets.map((assetToChange) => {
        if (assetToChange.id === formAsset.id) {
          return {
            ...assetToChange,
            name: formAsset.name,
            reference: formAsset.reference,
            class: formAsset.assetClass,
            type: formAsset.assetType,
            subType: formAsset.assetSubType,
            inspectionStatus: formAsset.status,
            suburb: formAsset.suburb,
            facility: formAsset.facility,
          };
        }
        return assetToChange;
      });
      setAssets(newAssets);
      refetch();
    } else {
      api
        .post('/api/Asset/Update', {
          asset: {
            ...formAsset,
            assetClassId: formAsset.assetClassId === 0 ? undefined : formAsset.assetClassId,
            assetSubTypeId: formAsset.assetSubTypeId === 0 ? undefined : formAsset.assetSubTypeId,
            assetTypeId: formAsset.assetTypeId === 0 ? undefined : formAsset.assetTypeId,
            acquisitionDate:
              formAsset.acquisitionDate !== undefined && formAsset.acquisitionDate !== null
                ? removeTimezoneOffset(formAsset.acquisitionDate)
                : undefined,
            decommissionDate:
              formAsset.decommissionDate !== undefined && formAsset.decommissionDate !== null
                ? removeTimezoneOffset(formAsset.decommissionDate)
                : undefined,
            indexedFromDate:
              formAsset.indexedFromDate !== undefined && formAsset.indexedFromDate !== null
                ? removeTimezoneOffset(formAsset.indexedFromDate)
                : undefined,
            errors: {},
            warnings: {},
            customFields: {},
          },
        })
        .then(({ data }) => {
          if (data === 0) {
            addToast(`Found duplicate Asset Id ${asset.reference}. Unable to save`, ToastType.Error);
          } else {
            addToast(`Created new Asset`);
            history(`/assets/${data}/edit`);
          }
        })
        .catch((error) => {
          addToast(`Unable to create new Asset`, ToastType.Error);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  });

  unstable_usePrompt({
    when: !isSubmitted && isDirty,
    message: 'You have unsaved changes, are you sure you want to leave?',
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="space-y-6">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">General Information</h3>
              {/* <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can receive mail.</p> */}
            </div>
            <div className="md:mt-0 md:col-span-2">
              <div className="bg-white space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <input
                      type="text"
                      {...register('name')}
                      id="name"
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Asset ID
                    </label>
                    <input
                      type="text"
                      {...register('reference')}
                      id="reference"
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {errors?.reference && <ErrorMessage>{errors.reference.message}</ErrorMessage>}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="customerReference" className="block text-sm font-medium text-gray-700">
                      Entity ID
                    </label>
                    <input
                      type="text"
                      {...register('customerReference')}
                      id="customerReference"
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <fieldset className="col-span-6 sm:col-span-6">
                    <div>
                      <legend className="text-base text-sm font-medium text-gray-700">Status</legend>
                    </div>
                    <div className="mt-4 space-y-4">
                      <div className="sm:col-span-3">
                        <div className="mt-4 flex">
                          <div className="flex mr-4">
                            <input
                              {...register('status')}
                              type="radio"
                              value="Not Required"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                              Not Required
                            </label>
                          </div>
                          <div className="flex mr-4">
                            <input
                              {...register('status')}
                              type="radio"
                              value="To Be Inspected"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                              To Be Inspected
                            </label>
                          </div>
                          <div className="flex mr-4">
                            <input
                              {...register('status')}
                              type="radio"
                              value="Inspected"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="push_email" className="ml-3 block text-sm font-medium text-gray-700">
                              Inspected
                            </label>
                          </div>
                          <div className="flex mr-4">
                            <input
                              {...register('status')}
                              type="radio"
                              value="Complete"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="push_email" className="ml-3 block text-sm font-medium text-gray-700">
                              Complete
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors?.status && <ErrorMessage>{errors.status.message}</ErrorMessage>}
                  </fieldset>

                  <fieldset className="col-span-6 sm:col-span-6">
                    <div>
                      <legend className="text-base text-sm font-medium text-gray-700">Asset History</legend>
                    </div>
                    <div className="mt-4 space-y-4">
                      <div className="sm:col-span-3">
                        <div className="mt-4 flex">
                          <div className="flex mr-4">
                            <input
                              {...register('history')}
                              type="radio"
                              value="New"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                              New
                            </label>
                          </div>
                          <div className="flex mr-4">
                            <input
                              {...register('history')}
                              type="radio"
                              value="Old"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                              Old
                            </label>
                          </div>
                          <div className="flex mr-4">
                            <input
                              {...register('history')}
                              type="radio"
                              value="Found"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="push_email" className="ml-3 block text-sm font-medium text-gray-700">
                              Found
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <div className="col-span-4 sm:colspan-3 lg:col-span-2">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Acquisition Date
                    </label>
                    <Controller
                      name="acquisitionDate"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }): JSX.Element => (
                        <ReactDatePicker
                          className={`max-w-lg block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 ${
                            errors?.acquisitionDate
                              ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900'
                              : 'shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                          }`}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Select date"
                          selected={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                    {errors?.acquisitionDate && <ErrorMessage>{errors.acquisitionDate.message}</ErrorMessage>}
                  </div>

                  <div className="col-span-4 sm:col-span-3 lg:col-span-2">
                    <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                      Decommission Date
                    </label>
                    <Controller
                      name="decommissionDate"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }): JSX.Element => (
                        <ReactDatePicker
                          className={`max-w-lg block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 ${
                            errors?.decommissionDate
                              ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900'
                              : 'shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                          }`}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Select date"
                          selected={value}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                    {errors?.decommissionDate && <ErrorMessage>{errors.decommissionDate.message}</ErrorMessage>}
                  </div>

                  {!isInsuranceOnly && (
                    <div className="col-span-4 sm:col-span-3 lg:col-span-2">
                      <label htmlFor="reference" className="block text-sm font-medium text-gray-700">
                        Indexed From Date
                      </label>
                      <Controller
                        name="indexedFromDate"
                        control={control}
                        render={({ field: { onChange, onBlur, value } }): JSX.Element => (
                          <ReactDatePicker
                            className={`max-w-lg block w-full shadow-sm focus:ring-indigo-500 rounded-md focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 ${
                              errors?.indexedFromDate
                                ? 'focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900'
                                : 'shadow focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                            }`}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select date"
                            selected={value}
                            onChange={onChange}
                            onBlur={onBlur}
                          />
                        )}
                      />
                      {errors?.indexedFromDate && <ErrorMessage>{errors.indexedFromDate.message}</ErrorMessage>}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white shadow mt-4 px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Asset Hierarchy/Facility</h3>
              {/* <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can receive mail.</p> */}
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="bg-white space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                      Asset Class
                    </label>
                    <select
                      defaultValue="Select an Asset Class"
                      {...register('assetClassId', {
                        valueAsNumber: true,
                        onChange: (e) => {
                          const { options, selectedIndex } = e.target;
                          setValue('assetClass', options[selectedIndex].innerHTML);
                        },
                      })}
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="Select an Asset Class">Select an Asset Class</option>
                      {assetClassList.map((assetClass) => (
                        <option key={assetClass.value} value={assetClass.value}>
                          {assetClass.label}
                        </option>
                      ))}
                    </select>
                    {errors?.assetClassId && <ErrorMessage>{errors.assetClassId.message}</ErrorMessage>}
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                      Asset Type
                    </label>
                    <select
                      defaultValue="Select an Asset Type"
                      {...register('assetTypeId', {
                        valueAsNumber: true,
                        onChange: (e) => {
                          const { options, selectedIndex } = e.target;
                          setValue('assetType', options[selectedIndex].innerHTML);
                        },
                      })}
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="Select an Asset Type">Select an Asset Type</option>
                      {assetTypeList
                        .sort((a, b) => (a.label > b.label ? 1 : -1))
                        .map((assetType) => (
                          <option key={assetType.value} value={assetType.value}>
                            {assetType.label}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label htmlFor="subType" className="block text-sm font-medium text-gray-700">
                      Asset Sub-Type
                    </label>
                    <select
                      defaultValue="Select a Sub Type"
                      {...register('assetSubTypeId', {
                        valueAsNumber: true,
                        onChange: (e) => {
                          const { options, selectedIndex } = e.target;
                          setValue('assetSubType', options[selectedIndex].innerHTML);
                        },
                      })}
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="Select a Sub Type">Select a Sub Type</option>
                      {assetSubTypeList.map((assetSubType) => (
                        <option key={assetSubType.value} value={assetSubType.value}>
                          {assetSubType.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="facility" className="block text-sm font-medium text-gray-700">
                      Facility
                    </label>
                    <input
                      type="text"
                      {...register('facility')}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="facility" className="block text-sm font-medium text-gray-700">
                      Sub-Facility
                    </label>
                    <input
                      type="text"
                      {...register('subFacility')}
                      defaultValue=""
                      className="mt-1 block w-full border border-gray-300 shadow-sm py-2 px-3 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  {/* <div className="col-span-6 sm:col-span-3 relative flex items-start">
    <div className="flex items-center h-5">
      <input
        ref={register}
        id="offers"
        name="isInsuranceRequired"
        type="checkbox"
        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
      />
    </div>
    <div className="ml-3 text-sm">
      <label htmlFor="offers" className="font-medium text-gray-700">
        Insurance Required
      </label>
    </div>
  </div> */}
                </div>
              </div>
              {/* <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
    <button
      type="submit"
      className="bg-indigo-600 border border-transparent shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      Save
    </button>
  </div> */}
            </div>
          </div>
        </div>
        <div className="pb-2">
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={(): void => {
                if (!isSubmitted && isDirty) {
                  if (window.confirm('You have unsaved changes, are you sure you want to leave?')) {
                    return parseInt(id) === 0 ? history('/') : history(-1);
                  }
                } else {
                  return parseInt(id) === 0 ? history('/') : history(-1);
                }
              }}
              className="btn btn-secondary"
            >
              Cancel
            </button>
            <button disabled={isSaving} type="submit" className="btn btn-primary">
              {isSaving ? (
                <>
                  <span>Saving</span>
                  <Loader className="animate-spin w-5 h-5 mx-2" />
                </>
              ) : (
                <span>Save</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
