import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useAssetById } from '../../hooks/UseAsset';
import { Asset } from '../../models/Asset';
import { useClient } from '../../contexts/ClientContext';

export const General: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: asset = {} as Asset } = useAssetById(parseInt(id));
  const { isInsuranceOnly } = useClient();

  const {
    financialAssetClass,
    financialAssetSubClass,
    facility,
    subFacility,
    isHighestAndBestUse,
    valuationClass,
    assetClass,
    assetType,
    assetSubType,
    assumptions,
  } = asset;

  return (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
      {!isInsuranceOnly ? (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Asset Class</dt>
          <dd className="mt-1 text-sm text-gray-900">{assetClass}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Financial Class</dt>
          <dd className="mt-1 text-sm text-gray-900">{financialAssetClass}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Asset Type</dt>
          <dd className="mt-1 text-sm text-gray-900">{assetType}</dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Financial Sub Class</dt>
          <dd className="mt-1 text-sm text-gray-900">{financialAssetSubClass}</dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Asset Sub-Type</dt>
          {assetSubType && (
            <NavLink className="btn-link font-medium" to={`/assetassumptions/${assumptions?.id}`}>
              {assetSubType}
            </NavLink>
          )}
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Valuation Class</dt>
          <dd className="mt-1 text-sm text-gray-900">{valuationClass?.name}</dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Facility</dt>
          <dd className="mt-1 text-sm text-gray-900">{facility}</dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Valuation Technique</dt>
          <dd className="mt-1 text-sm text-gray-900">{valuationClass?.valuationTechnique}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Sub-Facility</dt>
          <dd className="mt-1 text-sm text-gray-900">{subFacility}</dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Valuation Level</dt>
          <dd className="mt-1 text-sm text-gray-900">{valuationClass?.valuationLevel}</dd>
        </div>

        <div className="sm:col-span-1" />
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">H & B Use</dt>
          <dd className="mt-1 text-sm text-gray-900">{isHighestAndBestUse ? 'Yes' : 'No'}</dd>
        </div>
      </dl>) : (
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Asset Class</dt>
          <dd className="mt-1 text-sm text-gray-900">{assetClass}</dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Facility</dt>
          <dd className="mt-1 text-sm text-gray-900">{facility}</dd>
        </div>


        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Asset Type</dt>
          <dd className="mt-1 text-sm text-gray-900">{assetType}</dd>
        </div>
        
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Sub-Facility</dt>
          <dd className="mt-1 text-sm text-gray-900">{subFacility}</dd>
        </div>

        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Asset Sub-Type</dt>
          {assetSubType && (
            <NavLink className="btn-link font-medium" to={`/assetassumptions/${assumptions?.id}`}>
              {assetSubType}
            </NavLink>
          )}
        </div>

      </dl>
      )
      }
      
    </div>
  );
};

export default General;
