import React, { Fragment, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import * as yup from 'yup';
import { isValid } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { HierarchyType } from '../../models/HierarchyType';
import { Job } from '../../models/Job';
import { useApi } from '../../contexts/ApiContext';
import { ErrorMessage } from '../../components/ErrorMessage';
import { ToastType, useToast } from '../../contexts/ToastContext';
import { removeTimezoneOffset } from '../../Helper';
import Loader from '../../icons/Loader.svg?react';
import { useClient } from '../../contexts/ClientContext';

interface JobStatus {
  id: number;
  title: string;
  description: string;
}

const JobEdit: React.FC<{}> = () => {
  const api = useApi();
  const { id = '' } = useParams<{ id: string }>();
  const { fetchReadOnlyClasses, isInsuranceOnly } = useClient();
  const { addToast } = useToast();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const jobStatuses: JobStatus[] = [
    { id: 0, title: 'Open', description: 'This job is editable and all assets associated can be modified.' },
    { id: 1, title: 'Draft', description: 'This job is in a review state and all assets associated are read-only' },
    { id: 2, title: 'Finalised', description: 'This job is ready for archiving and all assets associated are read-only.' },
  ];
  const [selected, setSelected] = useState<JobStatus>(jobStatuses[0]);
  const history = useNavigate();
  const jobSchema = yup.object().shape({
    name: yup.string().max(100, 'Maximum length is 100.').required('Name for the job is required'),
    type: yup.string().required('Please select a type'),
    lastComprehensiveValuationDate: yup.date().when('type', {
      is: (val) => val === 'Indexation or Desktop' || val === 'Rolling',
      then: (schema) => schema.required('Last Comprehensive Date of Valuation required').typeError('Please enter a date'),
    }),

    effectiveDateOfValuation: yup
      .date()
      .typeError('Please enter a date')
      .when(['type', 'lastComprehensiveValuationDate'], ([type, lastComprehensiveValuationDate], schema) => {
        if (type === 'Indexation or Desktop' && !isInsuranceOnly) {
          const validDate = isValid(lastComprehensiveValuationDate);
          if (validDate) {
            const dayAfter = new Date(lastComprehensiveValuationDate.getTime());
            return schema.min(dayAfter, 'Date of Valuation must be on or after the Last Comprehensive Date of Valuation');
          }
          return yup.date().required('Last Comprehensive Date of Valuation').nullable();
        }
        return yup.date().required('Date of Valuation required').nullable();
      }),
  });

  const [assetClassList, setAssetClassList] = useState<HierarchyType[]>([]);
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<Job>({
    defaultValues: { id: parseInt(id), accountingStandard: 'IFRS' },
    reValidateMode: 'onBlur',
    resolver: yupResolver(jobSchema),
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    if (parseInt(id) > 0)
      api
        .get<Job>(`/api/Job/Get?jobId=${id}`)
        .then(({ data }) => {
          reset({
            ...data,
            type: data.type === 'Desktop' ? 'Indexation or Desktop' : data.type,
            effectiveDateOfValuation: new Date(data.effectiveDateOfValuation),
            lastComprehensiveValuationDate: new Date(data.lastComprehensiveValuationDate),
          });
          statusChange(data.status ?? '');
        })
        .catch((error) => {
          addToast(`Unable to get subscription with ID ${id}`, ToastType.Error);
        });
    api
      .get<HierarchyType[]>(`/api/AssetClass/ListWithNoJobs`)
      .then(({ data }) => {
        setAssetClassList(data);
      })
      .catch((error) => {});
  }, []);

  type Variables = { job: Job };
  const { mutate } = useMutation((params: Variables) => api.post('/api/Job/Update', params));
  const queryClient = useQueryClient();

  const onSubmit = handleSubmit((job: Job) => {
    setIsSaving(true);
    mutate(
      {
        job: {
          ...job,
          status: selected.title,
          effectiveDateOfValuation: removeTimezoneOffset(job.effectiveDateOfValuation),
          lastComprehensiveValuationDate:
            job.type === 'Comprehensive' &&
            (job.lastComprehensiveValuationDate !== null || job.lastComprehensiveValuationDate !== undefined)
              ? removeTimezoneOffset(job.effectiveDateOfValuation)
              : removeTimezoneOffset(job.lastComprehensiveValuationDate),
        },
      },
      {
        onSuccess: ({ data }) => {
          if (data === 0) {
            addToast(
              'Unable to change finalise job as there are outstanding errors for assets yet to be resolved.',
              ToastType.Error
            );
          } else if (data === -1) {
            addToast('Unable to create Job as there is already an existing Job with this name.', ToastType.Error);
          } else {
            addToast(`${parseInt(id) === 0 ? 'Created' : 'Updated'} job ${job.name}`);
            fetchReadOnlyClasses();
            history(`/jobs/${data}`);
          }
        },
        onError: (error) => {
          addToast(`Unable to ${parseInt(id) === 0 ? 'create' : 'update'} job ${job.name}`, ToastType.Error);
        },
        onSettled: () => {
          queryClient.invalidateQueries(['job', id]);
          queryClient.invalidateQueries(['jobslist']);
          queryClient.invalidateQueries(['assetCount', id]);
          queryClient.invalidateQueries(['assetClassListByJob', id]);
          setIsSaving(false);
        },
      }
    );
  });

  const statusChange = (s: string): void => {
    if (s === 'Open') {
      setSelected(jobStatuses[0]);
    } else if (s === 'Draft') {
      setSelected(jobStatuses[1]);
    } else if (s === 'Finalised') {
      setSelected(jobStatuses[2]);
    }
  };

  const isComprehensive = watch('type') === 'Comprehensive';

  return (
    <form onSubmit={onSubmit} className="px-4 pt-4 sm:px-6 lg:px-8 space-y-8 ">
      <div className="shadow rounded-md space-y-8 divide-y divide-gray-200 sm:space-y-5 bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <div className="sm:border-b sm:border-gray-200 sm:pb-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Job Information</h3>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input type="hidden" {...register('id', { valueAsNumber: true })} />
                <input
                  type="text"
                  {...register('name')}
                  disabled={selected.title !== 'Open'}
                  className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-600 disabled:opacity-50 focus:border-indigo-600 sm:max-w-xs sm:text-sm border-gray-300"
                />
                {errors?.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Job Number
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  {...register('externalId')}
                  disabled={selected.title !== 'Open'}
                  className="max-w-lg block w-full shadow-sm rounded-md disabled:opacity-50 focus:ring-indigo-600 focus:border-indigo-600 sm:max-w-xs sm:text-sm border-gray-300"
                />
                {errors?.name && <ErrorMessage>{errors.externalId?.message}</ErrorMessage>}
              </div>
            </div>

            <div className="pt-2 sm:pt-1">
              <div role="group" aria-labelledby="label-notifications">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="label-notifications"
                    >
                      Type
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="max-w-lg">
                      <div className="mt-4 space-y-4">
                        <div className="flex items-center">
                          <input
                            value="Comprehensive"
                            {...register('type')}
                            type="radio"
                            disabled={selected.title !== 'Open'}
                            className="rounded-md focus:ring-indigo-600 disabled:opacity-50 h-4 w-4 text-indigo-700 border-gray-300"
                          />
                          <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                            Comprehensive
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            value="Indexation or Desktop"
                            {...register('type')}
                            type="radio"
                            disabled={selected.title !== 'Open'}
                            className="rounded-md focus:ring-indigo-600 disabled:opacity-50 h-4 w-4 text-indigo-700 border-gray-300"
                          />
                          <label htmlFor="push_email" className="ml-3 block text-sm font-medium text-gray-700">
                            Desktop
                          </label>
                        </div>
                        {!isInsuranceOnly && (
                          <div className="flex items-center">
                          <input
                            value="Rolling"
                            {...register('type')}
                            type="radio"
                            disabled={selected.title !== 'Open'}
                            className="rounded-md focus:ring-indigo-600 disabled:opacity-50 h-4 w-4 text-indigo-700 border-gray-300"
                          />
                          <label htmlFor="push_email" className="ml-3 block text-sm font-medium text-gray-700">
                            Rolling
                          </label>
                        </div>
                        )}
                        
                        {errors?.type && <ErrorMessage>{errors.type.message}</ErrorMessage>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="status" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-3">
                Status
              </label>

              <Listbox value={selected} onChange={setSelected} by="id">
                {({ open }) => (
                  <>
                    <Label className="sr-only">Change published status</Label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
                        <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-600 px-2 py-1 text-white shadow-sm">
                          <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                          <p className="text-sm font-semibold">{selected.title}</p>
                        </div>
                        <ListboxButton className="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-600 p-2 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50">
                          <span className="sr-only">Change published status</span>
                          <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                        </ListboxButton>
                      </div>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <ListboxOptions className="absolute left-22 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {jobStatuses?.map((option) => (
                            <ListboxOption
                              key={option.id}
                              className={({ focus }) =>
                                classNames(
                                  focus ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                  'cursor-default select-none p-4 text-sm'
                                )
                              }
                              value={option}
                            >
                              {({ selected, focus }) => (
                                <div className="flex flex-col">
                                  <div className="flex justify-between">
                                    <p className={selected ? 'font-semibold' : 'font-normal'}>{option.title}</p>
                                    {selected ? (
                                      <span className={focus ? 'text-white' : 'text-indigo-600'}>
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </div>
                                  <p className={classNames(focus ? 'text-indigo-200' : 'text-gray-500', 'mt-2')}>
                                    {option.description}
                                  </p>
                                </div>
                              )}
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Date of Valuation
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Controller
                  name="effectiveDateOfValuation"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <ReactDatePicker
                      dateFormat="d MMM yyyy"
                      disabled={selected.title !== 'Open'}
                      className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 disabled:opacity-50 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                      selected={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
                {errors?.effectiveDateOfValuation && <ErrorMessage>{errors.effectiveDateOfValuation.message}</ErrorMessage>}
              </div>
            </div>

            {!isComprehensive && (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200">
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Last Comprehensive Valuation Date
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Controller
                    name="lastComprehensiveValuationDate"
                    control={control}
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                      <ReactDatePicker
                        dateFormat="d MMM yyyy"
                        disabled={selected.title !== 'Open'}
                        className="max-w-lg block w-full shadow-sm rounded-md focus:ring-indigo-500 disabled:opacity-50 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300"
                        selected={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                  {errors?.lastComprehensiveValuationDate && (
                    <ErrorMessage>{errors.lastComprehensiveValuationDate.message}</ErrorMessage>
                  )}
                </div>
              </div>
            )}

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Rounding
              </label>
              <div className="relative flex items-center col-span-2">
                <input
                  {...register('isRounding')}
                  type="checkbox"
                  disabled={selected.title !== 'Open'}
                  className="focus:ring-indigo-600 h-4 w-4 mr-3 disabled:opacity-50 text-indigo-700 rounded border-gray-300"
                />
                <label htmlFor="comments" className="text-sm text-gray-700">
                  Enable for rounding detailed estimates to an appropriate level{' '}
                  <span className="text-xs italic text-gray-500">(e.g 41,411.31 to 41,400; 1,004,983.92 to 1,005,000)</span>
                </label>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Asset Classes
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <Controller
                  name="assetClasses"
                  control={control}
                  render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <Select
                      isMulti
                      value={value}
                      onChange={onChange}
                      isDisabled={selected.title !== 'Open'}
                      options={assetClassList}
                      styles={{
                        multiValue: (base) => ({
                          ...base,
                          backgroundColor: '#eef2ff',
                          borderRadius: '0.375rem',
                          border: '1px solid #818cf8',
                        }),
                        multiValueLabel: (base) => ({
                          ...base,
                          color: '#4338ca',
                          fontWeight: 500,
                        }),
                        multiValueRemove: (base) => ({
                          ...base,
                          borderRadiusRight: '0.375rem',
                          color: '#4338ca',
                          margin: '2px',
                          ':hover': {
                            backgroundColor: '#a5b4fc',
                          },
                        }),
                      }}
                    />
                  )}
                />
              </div>
            </div>

            {!isInsuranceOnly && (
              <div className="pt-2 sm:pt-1">
                <div role="group" aria-labelledby="label-notifications">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                    <div>
                      <div
                        className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                        id="label-notifications"
                      >
                        Accounting Standard
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="max-w-lg">
                        <div className="mt-4 space-y-4">
                          <div className="flex items-center">
                            <input
                              value="IFRS"
                              {...register('accountingStandard')}
                              type="radio"
                              className="rounded-md focus:ring-indigo-600 disabled:opacity-50 h-4 w-4 text-indigo-700 border-gray-300"
                            />
                            <label htmlFor="push_everything" className="ml-3 block text-sm font-medium text-gray-700">
                              IFRS
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              value="IPSAS"
                              {...register('accountingStandard')}
                              type="radio"
                              className="rounded-md focus:ring-indigo-600 disabled:opacity-50 h-4 w-4 text-indigo-700 border-gray-300"
                            />
                            <label htmlFor="push_email" className="ml-3 block text-sm font-medium text-gray-700">
                              IPSAS
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Maintenance Plan
              </label>
              <div className="relative flex items-center col-span-2">
                <input
                  {...register('isMaintenance')}
                  type="checkbox"
                  disabled={selected.title !== 'Open'}
                  className="focus:ring-indigo-600 h-4 w-4 mr-3 disabled:opacity-50 text-indigo-700 rounded border-gray-300"
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Contents
              </label>
              <div className="relative flex items-center col-span-2">
                <input
                  {...register('isContents')}
                  type="checkbox"
                  disabled={selected.title !== 'Open'}
                  className="focus:ring-indigo-600 h-4 w-4 mr-3 disabled:opacity-50 text-indigo-700 rounded border-gray-300"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pb-2">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={(): void => {
              history(-1);
            }}
            className="bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm rounded-md font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-700 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
          >
            {isSaving && (
              <div className="flex space-x-4">
                <Loader className="animate-spin w-5 h-5" />
                <div>Saving</div>
              </div>
            )}
            {!isSaving && 'Save'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default JobEdit;
