import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';

import {
  BuildingOfficeIcon,
  UserGroupIcon,
  UsersIcon,
  BuildingLibraryIcon,
  RectangleStackIcon,
  ClipboardIcon,
} from '@heroicons/react/24/solid';
import { Bars3Icon, XMarkIcon, IdentificationIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import FolderOpen from '../icons/FolderOpen.svg?react';
import Briefcase from '../icons/Briefcase.svg?react';
import Folder from '../icons/Folder.svg?react';
import ArrowRight from '../icons/ArrowRight.svg?react';
import Logo from '../images/AVP_HorizontalLogo.svg?react';
import { UserSummary } from './UserSummary';

import { useAuth } from '../contexts/AuthContext';
import { Roles } from '../models/Role';
import { useClient } from '../contexts/ClientContext';
import HelpSlide from './HelpSlide';

interface Props {
  children?: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  const { roles, clientId, groupId, isAdminOfClient } = useAuth();
  const { isLeasesEnabled, isMaintenancePlansEnabled, isInsuranceOnly } = useClient();
  const isAdmin = roles.includes(Roles.Administrator) || roles.includes(Roles.GAdministrator);
  const [isAdminCollapsed, setIsAdminCollapsed] = useState<boolean>(true);
  const [isEasySamCollapsed, setIsEasySamCollapsed] = useState<boolean>(true);
  const [isHierarchyCollapsed, setIsHierarchyCollapsed] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </TransitionChild>
          <TransitionChild
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
              <TransitionChild
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={(): void => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <Logo className="h-20 w-48" />
                </div>
                <UserSummary
                  isAdmin={
                    roles.includes(Roles.Administrator) ||
                    roles.includes(Roles.GAdministrator) ||
                    roles.includes(Roles.CAdministrator)
                  }
                />
                <nav className="mt-5 px-2 space-y-1">
                  <Navigation
                    isAdminCollapsed={isAdminCollapsed}
                    setIsAdminCollapsed={setIsAdminCollapsed}
                    isHierarchyCollapsed={isHierarchyCollapsed}
                    setIsHierarchyCollapsed={setIsHierarchyCollapsed}
                    isEasySamCollapsed={isEasySamCollapsed}
                    setIsEasySamCollapsed={setIsEasySamCollapsed}
                    roles={roles}
                    isAdmin={isAdmin}
                    isLeasesEnabled={isLeasesEnabled}
                    isMaintenancePlansEnabled={isMaintenancePlansEnabled}
                    open={open}
                    setOpen={setOpen}
                  />
                </nav>
              </div>
              <div className="p-2 flex flex-col justify-end">
                <button
                  type="button"
                  onClick={(): void => setOpen(true)}
                  className="w-full mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 focus:outline-none transition ease-in-out duration-150"
                >
                  <QuestionMarkCircleIcon
                    className="mr-4 h-6 w-6 text-gray-400 group-hover:text-indigo-500 group-focus:text-indigo-500 transition ease-in-out duration-150"
                    aria-hidden="true"
                  />
                  Help
                </button>
              </div>
              <HelpSlide open={open} setOpen={setOpen} />
            </div>
          </TransitionChild>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-40 lg:w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
            <div className="flex-1 flex flex-col py-2 overflow-y-auto">
              <div className="flex-shrink-0 flex items-center px-2 text-white">
                <NavLink to="/">
                  <Logo className="h-12 w-36 lg:h-20 lg:w-48" />
                </NavLink>
              </div>
              <UserSummary
                isAdmin={
                  roles.includes(Roles.Administrator) ||
                  roles.includes(Roles.GAdministrator) ||
                  roles.includes(Roles.CAdministrator)
                }
              />
              <nav className="mt-5 flex-1 p-2 bg-white space-y-1">
                <Navigation
                  isAdminCollapsed={isAdminCollapsed}
                  setIsAdminCollapsed={setIsAdminCollapsed}
                  isHierarchyCollapsed={isHierarchyCollapsed}
                  setIsHierarchyCollapsed={setIsHierarchyCollapsed}
                  isEasySamCollapsed={isEasySamCollapsed}
                  setIsEasySamCollapsed={setIsEasySamCollapsed}
                  roles={roles}
                  isAdmin={isAdmin}
                  clientId={clientId}
                  groupId={groupId}
                  isAdminOfClient={isAdminOfClient}
                  isLeasesEnabled={isLeasesEnabled}
                  isInsuranceOnly={isInsuranceOnly}
                  isMaintenancePlansEnabled={isMaintenancePlansEnabled}
                  open={open}
                  setOpen={setOpen}
                />
              </nav>
              <div className="px-2 flex flex-col justify-end">
                <button
                  type="button"
                  onClick={(): void => setOpen(true)}
                  className="w-full mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 focus:outline-none transition ease-in-out duration-150"
                >
                  <QuestionMarkCircleIcon
                    className="mr-4 h-6 w-6 text-gray-400 group-hover:text-indigo-500 group-focus:text-indigo-500 transition ease-in-out duration-150"
                    aria-hidden="true"
                  />
                  Help
                </button>
              </div>
              <HelpSlide open={open} setOpen={setOpen} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={(): void => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">{children}</main>
      </div>
    </div>
  );
};

interface NavProps {
  isAdminCollapsed: boolean;
  isAdmin: boolean;
  setIsAdminCollapsed: (isAdminCollapsed: boolean) => void;
  roles: string[];
  isEasySamCollapsed: boolean;
  setIsEasySamCollapsed: (isEasySamCollapsed: boolean) => void;
  isHierarchyCollapsed: boolean;
  setIsHierarchyCollapsed: (isHierarchyCollapsed: boolean) => void;
  clientId?: number;
  groupId?: number;
  isAdminOfClient?: boolean;
  isLeasesEnabled: boolean;
  isInsuranceOnly: boolean;
  isMaintenancePlansEnabled: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const Navigation: React.FC<NavProps> = ({
  isAdminCollapsed,
  setIsAdminCollapsed,
  isEasySamCollapsed,
  setIsEasySamCollapsed,
  isHierarchyCollapsed,
  setIsHierarchyCollapsed,
  roles,
  isAdmin,
  clientId,
  groupId,
  isAdminOfClient,
  isLeasesEnabled,
  isInsuranceOnly,
  isMaintenancePlansEnabled,
  open,
  setOpen,
}) => (
  <>
    {(isAdminOfClient || isAdmin) && (
      <div className="space-y-1">
        <button
          onClick={(): void => setIsAdminCollapsed(!isAdminCollapsed)}
          className="group w-full flex items-center pl-2 pr-1 py-2 text-sm font-medium rounded-md bg-white text-gray-600 hover:text-indigo-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          {/* Heroicon name: outline/folder */}
          {isAdminCollapsed ? (
            <Folder className="text-gray-400 group-hover:text-indigo-500 mr-3 h-6 w-6" />
          ) : (
            <FolderOpen className="text-gray-400 group-hover:text-indigo-500 mr-3 h-6 w-6" />
          )}
          Administration
          {/* expanded: "text-gray-400 rotate-90", Collapsed: "text-gray-300" */}
          <ArrowRight
            className={`ml-auto h-5 w-5 transform group-hover:text-gray-400 ${
              !isAdminCollapsed && 'text-gray-400 rotate-90'
            } ${isAdminCollapsed && 'text-gray-300'} transition-colors ease-in-out duration-150`}
          />
        </button>
        <div hidden={isAdminCollapsed} className=" space-y-1">
          {roles.includes(Roles.Administrator) && (
            <NavLink
              to="subscriptions"
              className={({ isActive }) =>
                `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 ${
                  isActive && 'text-gray-900 bg-gray-100'
                }`
              }
            >
              <RectangleStackIcon className="mr-4 h-6 w-6 text-gray-400 group-hover:text-indigo-500 group-focus:text-indigo-500 transition ease-in-out duration-150" />
              Subscriptions
            </NavLink>
          )}
          {roles.includes(Roles.Administrator) && (
            <NavLink
              to="groups"
              className={({ isActive }) =>
                `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 ${
                  isActive && 'text-gray-900 bg-gray-100'
                }`
              }
            >
              <BuildingLibraryIcon className="mr-4 h-6 w-6 text-gray-400 group-hover:text-indigo-500 group-focus:text-indigo-500 transition ease-in-out duration-150" />
              Groups
            </NavLink>
          )}
          {roles.includes(Roles.GAdministrator) && (
            <NavLink
              to={`groups/${groupId}`}
              className={({ isActive }) =>
                `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 ${
                  isActive && 'text-gray-900 bg-gray-100'
                }`
              }
            >
              <BuildingLibraryIcon className="mr-4 h-6 w-6 text-gray-400 group-hover:text-indigo-500 group-focus:text-indigo-500 transition ease-in-out duration-150" />
              Group
            </NavLink>
          )}
          {(roles.includes(Roles.Administrator) || roles.includes(Roles.GAdministrator)) && (
            <NavLink
              to="clients"
              className={({ isActive }) =>
                `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 ${
                  isActive && 'text-gray-900 bg-gray-100'
                }`
              }
            >
              <UserGroupIcon className="mr-4 h-6 w-6 text-gray-400 group-hover:text-indigo-500 group-focus:text-indigo-500 transition ease-in-out duration-150" />
              Clients
            </NavLink>
          )}

          {roles.includes(Roles.CAdministrator) && (
            <NavLink
              to={`clients/${clientId}`}
              className={({ isActive }) =>
                `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 ${
                  isActive && 'text-gray-900 bg-gray-100'
                }`
              }
            >
              <IdentificationIcon className="mr-4 h-6 w-6 text-gray-400 group-hover:text-indigo-500 group-focus:text-indigo-500 transition ease-in-out duration-150" />
              Client Info
            </NavLink>
          )}

          {roles.includes(Roles.Administrator) && (
            <NavLink
              to="users"
              className={({ isActive }) =>
                `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 ${
                  isActive && 'text-gray-900 bg-gray-100'
                }`
              }
            >
              <UsersIcon className="mr-4 h-6 w-6 text-gray-400 group-hover:text-indigo-500 group-focus:text-indigo-500 transition ease-in-out duration-150" />
              Users
            </NavLink>
          )}

          {/* {roles.indexOf('Administrator') > 1 ? (
                      <NavLink
                        to="/external"
                                      className={({ isActive }) =>
                          `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 ${
                            isActive && 'text-gray-900 bg-gray-100'
                          }`
                        }
                      >
                        External Clients
                      </NavLink>
                    ) : (
                      <button className="disabled group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-400 rounded-md">
                        External Clients
                      </button>
                    )} */}
        </div>
      </div>
    )}
    {!roles.includes(Roles.Administrator) && !roles.includes(Roles.GAdministrator) && (
      <>
        <div className="space-y-1">
          <button
            onClick={(): void => setIsHierarchyCollapsed(!isHierarchyCollapsed)}
            className="group w-full flex items-center pl-2 pr-1 py-2 text-sm font-medium rounded-md bg-white text-gray-600 hover:text-indigo-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            {isHierarchyCollapsed ? (
              <Folder className="text-gray-400 group-hover:text-indigo-500 mr-3 h-6 w-6" />
            ) : (
              <FolderOpen className="text-gray-400 group-hover:text-indigo-500 mr-3 h-6 w-6" />
            )}
            Framework
            <ArrowRight
              className={`ml-auto h-5 w-5 group-hover:text-gray-400 ${!isHierarchyCollapsed && 'text-gray-400 rotate-90'} ${
                isHierarchyCollapsed && 'text-gray-300'
              } transition-colors ease-in-out duration-150`}
            />
          </button>
          <div hidden={isHierarchyCollapsed} className="space-y-1">
            {!roles.includes(Roles.Viewer) && (
              <NavLink
                to="framework"
                className={({ isActive }) =>
                  `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 ${
                    isActive && 'text-gray-900 bg-gray-100'
                  }`
                }
              >
                Import/Export
              </NavLink>
            )}
            <NavLink
              to="assetassumptions/search"
              className={({ isActive }) =>
                `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 ${
                  isActive && 'text-gray-900 bg-gray-100'
                }`
              }
            >
              Asset Assumptions
            </NavLink>
            <NavLink
              to="insuranceassumptions/search"
              className={({ isActive }) =>
                `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 ${
                  isActive && 'text-gray-900 bg-gray-100'
                }`
              }
            >
              Insurance Assumptions
            </NavLink>
            <NavLink
              to="componentassumptions/search"
              className={({ isActive }) =>
                `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 ${
                  isActive && 'text-gray-900 bg-gray-100'
                }`
              }
            >
              Component Assumptions
            </NavLink>
            <NavLink
              to="hierarchy/browse"
              className={({ isActive }) =>
                `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 ${
                  isActive && 'text-gray-900 bg-gray-100'
                }`
              }
            >
              Hierarchy
            </NavLink>
          </div>
        </div>
        <NavLink
          to="jobs"
          className={({ isActive }) =>
            `mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150 ${
              isActive && 'text-gray-900 bg-gray-100'
            }`
          }
        >
          <Briefcase className="mr-4 h-6 w-6 text-gray-400 group-hover:text-indigo-500 group-focus:text-indigo-500 transition ease-in-out duration-150" />
          Jobs
        </NavLink>
        <NavLink
          to="assets/search"
          className={({ isActive }) =>
            `mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150 ${
              isActive && 'text-gray-900 bg-gray-100'
            }`
          }
        >
          <BuildingOfficeIcon className="mr-4 h-6 w-6 text-gray-400 group-hover:text-indigo-500 group-focus:text-indigo-500 transition ease-in-out duration-150" />
          Assets
        </NavLink>
      </>
    )}
    {!roles.includes('Administrator') && !roles.includes('Group Administrator') && !isInsuranceOnly && (
      <div className="space-y-1">
        <h3 className="px-3 text-sm font-medium text-gray-500" id="projects-headline">
          Projects
        </h3>
        <div className="space-y-1" role="group" aria-labelledby="projects-headline">
          <div className="space-y-1">
            {/* {roles.indexOf('Administrator') === 1 && ( */}
            <button
              onClick={(): void => setIsEasySamCollapsed(!isEasySamCollapsed)}
              className="disabled:opacity-50 group w-full flex items-center pl-2 pr-1 py-2 text-sm font-medium rounded-md bg-white text-gray-600 hover:text-indigo-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {/* Heroicon name: outline/folder */}
              {isEasySamCollapsed ? (
                <Folder className="text-gray-400 group-hover:text-indigo-500 mr-3 h-6 w-6" />
              ) : (
                <FolderOpen className="text-gray-400 group-hover:text-indigo-500 mr-3 h-6 w-6" />
              )}
              Easy SAM
              {/* expanded: "text-gray-400 rotate-90", Collapsed: "text-gray-300" */}
              <ArrowRight
                className={`ml-auto h-5 w-5 transform group-hover:text-gray-400 ${
                  !isEasySamCollapsed ? 'text-gray-400 rotate-90' : 'text-gray-300'
                } transition-colors ease-in-out duration-150`}
              />
            </button>
            {/* )} */}
            {/* Expandable link section, show/hide based on state. */}
            <div hidden={isEasySamCollapsed} className="space-y-1">
              <NavLink
                to="UpdateHierarchy"
                className={({ isActive }) =>
                  `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 ${
                    isActive && 'text-gray-900 bg-gray-100'
                  }`
                }
              >
                Baseline Strategies
              </NavLink>
              <NavLink
                to="Strategy"
                className={({ isActive }) =>
                  `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 ${
                    isActive && 'text-gray-900 bg-gray-100'
                  }`
                }
              >
                Alternative Strategies
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    )}

    {!roles.includes('Administrator') && !roles.includes('Group Administrator') && isLeasesEnabled && (
      <NavLink
        to="leases/search"
        className={({ isActive }) =>
          `mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150 ${
            isActive && 'text-gray-900 bg-gray-100'
          }`
        }
      >
        <ClipboardIcon className="mr-4 h-6 w-6 text-gray-400 group-hover:text-indigo-500 group-focus:text-indigo-500 transition ease-in-out duration-150" />
        Leases
      </NavLink>
    )}
    {!roles.includes('Administrator') && !roles.includes('Group Administrator') && isMaintenancePlansEnabled && (
      <NavLink
        to="maintenanceplans/search"
        className={({ isActive }) =>
          `mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-indigo-600 hover:bg-gray-50 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150 ${
            isActive && 'text-gray-900 bg-gray-100'
          }`
        }
      >
        <ClipboardIcon className="mr-4 h-6 w-6 text-gray-400 group-hover:text-indigo-500 group-focus:text-indigo-500 transition ease-in-out duration-150" />
        Maintenance Plans
      </NavLink>
    )}
  </>
);

export default Layout;
