import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { useApi } from './ApiContext';
import { useAuth } from './AuthContext';
import { Client } from '../models/Client';

interface ClientContext {
  readOnlyAssetClasses: string[];
  removeReadOnlyAssetClasses(): void;
  fetchReadOnlyClasses: () => void;
  isLeasesEnabled: boolean;
  isInsuranceOnly: boolean;
  isMaintenancePlansEnabled: boolean;
  checkAssetClassEditable: (assetClass: string) => boolean;
}

const ClientContext = createContext<ClientContext>({} as ClientContext);

export const ClientProvider: React.FC<ReactNode> = ({ children }) => {
  const [readOnlyAssetClasses, setReadOnlyAssetClasses] = useState<string[]>([]);
  const [isLeasesEnabled, setIsLeasesEnabled] = useState<boolean>(false);
  const [isInsuranceOnly, setIsInsuranceOnly] = useState<boolean>(false);
  const [isMaintenancePlansEnabled, setIsMaintenancePlansEnabled] = useState<boolean>(false);
  const { clientId } = useAuth();
  const api = useApi();

  const checkAssetClassEditable = (assetClass: string): boolean => !readOnlyAssetClasses.includes(assetClass);

  const fetchReadOnlyClasses = (): void => {
    api.get(`/api/Job/List`).then(({ data }) => {
      const staticJobs = data.filter((job) => job.status !== 'Open' && job.assetClasses.length > 0);
      const staticJobArray = [] as string[];
      staticJobs.forEach((job) => {
        job.assetClasses.forEach((ac) => staticJobArray.push(ac.label));
      });
      setReadOnlyAssetClasses(staticJobArray);
      // window.sessionStorage.setItem('readOnlyAssetClasses', JSON.stringify(staticJobArray.join('/')));
    });
  };

  useEffect(() => {
    if (readOnlyAssetClasses.length === 0) {
      fetchReadOnlyClasses();
    }
  }, []);

  useEffect(() => {
    if (clientId > 0) {
      api.get<Client>(`api/Client/Get?id=${clientId}`).then(({ data }) => {
        setIsLeasesEnabled(data.isLeasesEnabled);
        setIsInsuranceOnly(data.isInsuranceOnly);
        setIsMaintenancePlansEnabled(data.isMaintenancePlansEnabled);
      });
    }
  }, [clientId]);

  const removeReadOnlyAssetClasses = (): void => {
    setReadOnlyAssetClasses([]);
  };

  return (
    <ClientContext.Provider
      value={{
        readOnlyAssetClasses,
        removeReadOnlyAssetClasses,
        isLeasesEnabled,
        isInsuranceOnly,
        isMaintenancePlansEnabled,
        fetchReadOnlyClasses,
        checkAssetClassEditable,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = (): ClientContext => useContext(ClientContext);
